import styled from "styled-components"
import { PRIMARY_COLOR, SECONDARY_COLOR, DARK_COLOR } from "../../utils/colors";

export default styled.section`
  font-size: 1.2rem;
  line-height: 1.6rem;
  margin-bottom: 1rem;
  color: ${DARK_COLOR};
  h1 {
    font-weight: 700;
    font-size: 2.8rem;
    line-height: 3.1rem;
    margin-bottom: 1rem;
    color: ${SECONDARY_COLOR};
  }
  h2 {
    font-weight: 700;
    font-size: 1.1rem;
    line-height: 1.4rem;
    margin-bottom: 1rem;
    color: ${PRIMARY_COLOR};
    a {
      color: ${PRIMARY_COLOR};
      &:hover {
        text-decoration: underline;
      }
    }
  }
  h3 {
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 1.8rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    color: ${SECONDARY_COLOR};
  }
  h4 {
    font-weight: 700;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  ul {
    list-style: disc;
    ul {
      list-style: circle;
    }
  }
  ol {
    list-style: decimal;
  }
  li {
    margin-left: 17px;
  }
`
