import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Section from "../components/section"
import Container from "../components/container"
import SEO from "../components/seo"
import Markdown from "../components/markdown"
import Typography from "../components/typography"

const Post = ({ data, ...rest }) => {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  return (
    <Layout {...rest}>
      <SEO title={frontmatter.title} description={frontmatter.description} />
      <Section padding="2rem 0">
        <Container>
          <Markdown
            className="blog-post-content"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </Container>
      </Section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
      }
    }
  }
`

Post.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default Post
